import React, { Component, useEffect, useState, useRef } from "react";
import { Container } from "reactstrap";
import logo from "../../Containers/logo.png";

function Header(props) {
  // const [modal, setModal] = useState(false);

  // const toggle = () => setModal(!modal);
  // const closeBtn = (
  //   <button className="close" onClick={toggle} type="button">
  //     &times;
  //   </button>
  // );
  return (
    <header className="header">
      {/* <a href ="https://app.daedalusfi.com/"> <img src={logo} className="App-logo" alt="logo" />
      </a> */}
      <div className="d-flex align-items-center">
        <i className="fas fa-bars mr-2 gradient"></i>
        <h3 onClick={props.model} className="gradient mb-0 font-weight-bold">
          Posts
        </h3>
        <i className="fas fa-search ml-auto"></i>
      </div>
    </header>
  );
}

export default Header;
