import React, { Component, useEffect, useState, useRef } from "react";
import Post from "../../Components/Post/Post.js";
import logo from "../logo.png";
import { db } from "../../firebase.js";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import Header from "../../Components/Header/Header.js";
import { Container } from "reactstrap";

import Ios from "../../assets/imgs/app-store.png";
import android from "../../assets/imgs/google-play.png";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Helmet } from "react-helmet";

function Home() {
  const renderCountRef = useRef(0);

  const [posts, setPosts] = useState([]);
  const [modal, setModal] = useState(false);
  const [lastDocument, setLastDocument] = useState(null);

  const toggle = () => setModal(!modal);
  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      &times;
    </button>
  );

  const loadPosts = async () => {
    try {
      // Get reference
      const postsCollectionRef = collection(db, "posts");
      // Fetch all documents from the collection
      // const getAllPosts = await getDocs(
      //   query(postsCollectionRef, orderBy("created_at", "desc"), limit(10))
      // );
      let getAllPosts = null;

      if (lastDocument) {
        getAllPosts = await getDocs(
          query(
            postsCollectionRef,
            orderBy("created_at", "desc"),
            limit(10),
            startAfter(lastDocument)
          )
        );
      } else if (renderCountRef.current == 0) {
        renderCountRef.current++;
        getAllPosts = await getDocs(
          query(postsCollectionRef, orderBy("created_at", "desc"), limit(10))
        );
      }
      let x = 0;
      let postsArr = posts;
      while (x < getAllPosts.docs.length) {
        console.log(getAllPosts.docs[x].data());
        let obj = postsArr.find(
          (itx) => itx.post_id == getAllPosts.docs[x].data().post_id
        );
        if (obj == null) {
          postsArr.push(getAllPosts.docs[x].data());
          postsArr[x].url = "/post/" + postsArr[x].post_id;

          x++;
        }
      }
      //order the posts by the date they were created
      //This might not be working correctly
      postsArr.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      setPosts(postsArr);
      const lastDoc = getAllPosts.docs[getAllPosts.docs.length - 1] || null;
      setLastDocument(lastDoc);
    } catch (error) {}
  };
  useEffect(() => {
    loadPosts();
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>Daedalus Feed</title>
        <meta name="description" content="Daedalus Feed" />
      </Helmet>
      <Container>
        <Header model={toggle} />
        <div className="d-flex align-items-center justify-content-between border-bottom border-site-secondary pb-2">
          <div onClick={toggle} className="d-flex align-items-center">
            <i className="fas fa-sort-amount-down mr-3"></i>
            <p className="mb-0">Latest</p>
            <i className="fas fa-sort-down fa-1 ml-3 mt-n2"></i>
          </div>
          <div
            onClick={toggle}
            className="d-flex align-items-center text-site-success"
          >
            <p className="mb-0 mr-3 font-weight-bold">Add Post</p>{" "}
            <i className="fas fa-plus"></i>
          </div>
        </div>
        <Col md="6" className="mx-auto mt-3">
          {posts.map((post, index) => {
            return (
              // <Link to={post.url} className="mb-3">
              <Post
                key={index}
                id={post.post_id}
                url={post.url}
                model={toggle}
              />
              //  </Link>
            );
          })}
          <Button
            onClick={() => {
              loadPosts();
            }}
            color="success"
            className="mx-auto d-flex mb-5"
          >
            Load More...
          </Button>
        </Col>
      </Container>
      <Modal isOpen={modal} toggle={toggle} className="text-dark">
        <ModalHeader toggle={toggle} close={closeBtn}></ModalHeader>
        <ModalBody>
          Download the Daedalus app.
          <br />
          <div className="d-flex">
            <Button
              color="link"
              href="https://testflight.apple.com/join/r0G4P7dR"
              target="_blank"
              className="p-0  bg-transparent mr-2"
            >
              <img src={Ios} className="btn_link" />
            </Button>
            <Button
              color="link"
              href=""
              target="_blank"
              className="p-0  bg-transparent"
            >
              <img src={android} className="btn_link" />
            </Button>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Home;
