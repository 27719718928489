import React, { Component, useEffect, useState, useRef } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import { db } from "../../firebase.js";
import moment from "moment";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import Header from "../../Components/Header/Header.js";
import { Link, useParams } from "react-router-dom";
import link_img from "../../assets/imgs/example.png";
import verified from "../../assets/imgs/verified.png";
import Ios from "../../assets/imgs/app-store.png";
import android from "../../assets/imgs/google-play.png";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ShareModal from "../ShareModal.js";
import { Helmet } from "react-helmet";

function Post(props) {
  const [post, setPost] = useState({});
  // const [comments, setComments] = useState([]);
  // const [user, setUser] = useState({});

  const { id } = useParams();

  const [sharemodal, setSharemodal] = useState(false);

  const share = () => {
    let urls = "https://www.facebook.com/sharer/sharer.php?u=" + props.url;
  };
  const toggle = () => setSharemodal(!sharemodal);
  const loadProfile = async (sender_id) => {
    try {
      // console.log(sender_id);
      //Uses sender_id field from the post to get the data from the user collection of the user who created the post
      //Get reference
      const userCollectionRef = collection(db, "USERS");
      //Create query
      // const getUser = await query(
      //   userCollectionRef,
      //   where("uid", "==", sender_id)
      // );

      const getUser = doc(userCollectionRef, sender_id);

      // const q = query(collection(db, "cities"), where("capital", "==", true));

      //Get the user data
      // querySnapshot.forEach((doc) => {
      //   // doc.data() is never undefined for query doc snapshots
      //   console.log(doc.id, " => ", doc.data());
      // });
      const docSnap = await getDoc(getUser);

      // const user = await getDocs(getUser).data();

      return docSnap.data();
    } catch (error) {
      console.log(error.message);
    }
  };
  // const dateTimeAgo = moment(new Date(post.created_at.nanoseconds)).fromNow();
  // console.log(po);
  useEffect(() => {
    const loadPost = async (id) => {
      try {
        // Get reference
        const postsCollectionRef = collection(db, "posts");

        // Create a query
        //Finds the post with id equal to the id passed in

        const getCorrectPostQuery = query(
          postsCollectionRef,
          where("post_id", "==", id)
        );

        // Execute query

        const getCorrectPost = await getDocs(getCorrectPostQuery);

        const correctPost =
          getCorrectPost.docs[getCorrectPost.docs.length - 1].data();
        let data = await loadProfile(correctPost.sender_id);
        await setPost({ ...correctPost, user: data });
        // console.log("innerpost", post);
      } catch (error) {
        console.log(error);
      }
    };

    loadPost(props.id);
  }, [props.id]);
  const getDifferenceInDays = (date) => {
    var now = moment(new Date()); //todays date
    var end = moment(date?.toDate()); // another date
    var duration = moment.duration(now.diff(end));
    var days = Math.round(duration.asDays());
    // console.log("days difference ", date?.toDate(), days);
    return days;
  };

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      &times;
    </button>
  );

  function shareOnFacebook() {
    const navUrl = "https://www.facebook.com/sharer/sharer.php?u=" + props.url;
    window.open(navUrl);
  }

  const getShortSentence = (text) => {
    if (text) {
      return text.split(/\s+/).slice(0, 20).join(" ");
    } else {
      return "";
    }
  };

  return (
    <div className="App mb-4 cursor-pointer">
      {id && (
        <Helmet>
          <title>{`${post?.title}`}</title>
          <meta
            name="description"
            content={`${getShortSentence(post?.text)}`}
          />
        </Helmet>
      )}

      <div className="p-3 rounded bg-site-secondary">
        <div className="d-flex align-items-center justify-content-between post_header">
          <div className="d-flex align-items-center " onClick={props.model}>
            {post?.user?.photo_url ? (
              <img src={post.user.photo_url} className="img_profile" />
            ) : (
              <small className="bg-secondary d-flex align-items-center justify-content-center img_profile text-uppercase">
                {post?.user?.firstname.slice(0, 3)}
              </small>
            )}
            <div
              className="mx-2 font-weight-bold"
              style={{
                fontSize: "14px",
              }}
            >
              {post?.user?.firstname} {post?.user?.lastname}
            </div>
            <i className="fas fa-badge-check"></i>
            {post?.user?.verified == true && <img src={verified} width="13" />}
          </div>
          <div className="d-flex align-items-center">
            <p
              className="mb-0 mr-2"
              style={{
                fontSize: "10px",
              }}
            >
              {/* 3d ago */}
              {getDifferenceInDays(post.created_at)} days ago
            </p>{" "}
            <a onClick={props.model}>
              <i className="fas fa-ellipsis-v"></i>
            </a>
          </div>{" "}
        </div>
        <Link
          to={props.url}
          // onClick={(event) => {
          //   event.preventDefault();
          //   toggle(true);
          // }}
        >
          <div className="post_content py-2">
            <h4 style={{ fontSize: "10px" }}> {post.title}</h4>
            <p
              style={{
                fontSize: "10px",
              }}
            >
              {post.text}
            </p>
          </div>
        </Link>
        {/* <Row className="rounded bg-site-muted p-2 d-flex mx-0">
          <Col xs="3" className="p-0">
            <img src={link_img} width="100%" />
          </Col>
          <Col xs="9" className="p-">
            <small>
              <strong>Example link header</strong>
            </small>
            <br />
            <small>Example link text</small> <br />
            <a href="" className="text-site-success">
              https://www.w3schools.com/
            </a>
          </Col>
        </Row> */}
        <div className="my-3">
          {" "}
          {post?.media_urls?.length > 0 && (
            <img
              src={post.media_urls[0]}
              className="rounded"
              width="70%"
              onClick={props.model}
            />
          )}
        </div>
        <div className="d-flex">
          <a className="small text-site-success" onClick={props.model}>
            <i className="fas fa-arrow-up d-flex">
              {" "}
              <p className="mb-0 mx-2">{post.up_count}</p>
            </i>
          </a>
          <a className="small text-secondary mx-2" onClick={props.model}>
            <i className="fas fa-arrow-down  d-flex">
              {" "}
              <p className="mb-0 mx-2"></p>
            </i>
          </a>
          <a className="small text-secondary mx-2" onClick={props.model}>
            <i className="far fa-comment-alt d-flex">
              {" "}
              <p className="mb-0 mx-2">{post.comment_count} </p>
            </i>
          </a>
          <a
            className="small text-secondary ml-auto"
            target="_blank"
            href={`https://www.facebook.com/sharer/sharer.php?u=https://web-feed.web.app${props.url}`}
          >
            <i className="fas fa-share-alt"></i>
          </a>
        </div>
      </div>
      {/* <Modal isOpen={modal} toggle={toggle} className="text-dark">
        <ModalHeader toggle={toggle} close={closeBtn}></ModalHeader>
        <ModalBody>
          Download the Daedalus app.
          <br />
          <div className="d-flex">
            <Button
              color="link"
              href="https://testflight.apple.com/join/r0G4P7dR"
              target="_blank"
              className="p-0  bg-transparent mr-2"
            >
              <img src={Ios} className="btn_link" />
            </Button>
            <Button
              color="link"
              href=""
              target="_blank"
              className="p-0  bg-transparent"
            >
              <img src={android} className="btn_link" />
            </Button>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal> */}
    </div>
  );
}

export default Post;
