import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./Containers/Home/Home.js";
import Posts from "./Containers/Posts/Posts.js";

function App() {
  return (
    <>
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/post/:id" element={<Posts />} />
            <Route path="/topic/:topic" element={<Posts />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
