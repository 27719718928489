import React, { Component, useEffect, useState, useRef } from "react";
import logo from "../logo.svg";
import { useParams, Link } from "react-router-dom";
import Post from "../../Components/Post/Post.js";
import Header from "../../Components/Header/Header.js";
import { Container } from "reactstrap";

function Posts(props) {
  let postId = useParams();
  let url = "/post/" + postId.id;
  return (
    <div className="App">
      <Container>
        <Header />
        <div style={{ paddingTop: "100px" }}>
          <Post id={postId.id} url={url} />
        </div>
      </Container>
    </div>
  );
}

export default Posts;
