import React from "react";
import { ModalHeader } from "react-bootstrap";
import { FacebookButton, FacebookCount } from "react-social";
import { Modal } from "reactstrap";

function ShareModal(props) {
  let url = "https://www.facebook.com/sharer/sharer.php?u={url}";
  return (
    <Modal isOpen={props.openmodel} toggle={props.toggle} className="text-dark">
      <ModalHeader toggle={props.toggle} close={props.close}></ModalHeader>
      <FacebookButton url={url}>
        <FacebookCount url={url} />
        {" Share " + url}
      </FacebookButton>
    </Modal>
  );
}

export default ShareModal;
